@import "styles/vars";

.withdraw-balance-form{
  width: 100%;
  &__grid {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 8px;
  }
  &__aside {
    flex-shrink: 0;
    max-width: 253px;
    width: 100%;
    min-height: 450px;
    padding-right: 28px;
    border-right: 1px solid $color-border;
    &--list{
      width: 100%;
      margin-top: 20px;
      &-item{
        width: 100%;
        padding: 0 8px;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-icon{
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
        &-label{
          display: flex;
          align-items: center;
          justify-content: center;
          span{
            color: $color-white;
            @include fs--title-7-pc;
            @include tr-ease();
          }
        }
        &-value{
          color: $color-grey-text;
          @include fs--headline-2-pc;
          @include tr-ease();
        }
        @include tr-ease();
        & + & {
          margin-top: 8px;
        }
        &:hover,&:active{
          background: $color-bg-3;
        }
        &--active {
          .withdraw-balance-form__aside--list-item{
            &-label{
              span{
                color: $color-purple-3;
              }
            }
            &-value{
              color: $color-white;
            }
          }
          background: $color-purple-0;
        }
      }
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 28px;
    &--title {
      color: var(--new-white, #FFF);
      @include fs--title-3-pc();
      &.mobile{
        display: none;
      }
    }
    &--form{
      max-height: 300px;
      padding-right: 28px;
      @include scrollbar-modal();
      overflow: auto;
      &-item{
        &-label{
          @include fs--headline-4-pc;
          color: $color-white;
          margin-bottom: 8px;
        }
      }
      &-item + &-item{
        margin-top: 20px;
      }
    }
    &--balance{
      margin: 32px 32px 28px 0;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid $color-border;
      @include fs--headline-2-pc;
      span{
        color: $color-white;
        @include fs--headline-3-pc;
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 28px 32px;
    background: var(--new-bg-3, #1F1E21);
    &--value{
      h3{
        color: $color-white;
        @include fs--title-4-pc;
        margin-bottom: 12px;
        margin-top: 0;
      }
      span{
        color: $color-grey-text;
        @include fs--title-7-pc;
      }
    }
  }
}

@media screen and (max-width: 1024px){
  .withdraw-balance-form{
    &__grid{
      flex-direction: column;
      padding: 0;
    }
    &__aside{
      padding: 26px 16px 0 16px;
      max-width: none;
      border-right: none;
      border-bottom: 1px solid $color-border;
      min-height: 0;
      &--list{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        overflow-x: scroll;
        @include scrollbar('horizontal', 4px);
        margin-bottom: 16px;
        &-item + &-item{
          margin-top: 0;
        }
        &-item{
          &-label{
            span{
              @include fs--headline-4-pc;
              white-space: nowrap;
            }

            margin-right: 8px;
          }
        }
      }
    }
    &__footer{
      padding: 24px 16px;
      &--value{
        h3{
          @include fs--title-6-pc;
        }
        span{
          @include fs--headline-4-pc;
        }
      }
    }
    &__main{
      padding-left: 16px;
      &--balance{
        margin-right: 16px;
      }
      &--form{
        padding-right: 12px;
        margin-bottom: 20px;
      }
      &--title{
        &.mobile{
          display: inline-block;
          margin-bottom: 22px;
          @include fs--title-4-pc;
        }
        &.desktop{
          display: none;
        }
      }
    }
  }
}