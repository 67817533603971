@import 'src/styles/vars';

.ant-table-wrapper {
  .ant-table {
    overflow: hidden;
    border-radius: 12px;
    @include fs--headline-2-pc;
    background: var(--new-bg-2, #19181C);

    &.ant-table-scroll-horizontal {
      &.ant-table-ping-left,
      &.ant-table-ping-right {
        .ant-table-content {
          margin-bottom: 8px;
          padding-bottom: 8px;
        }
      }

      .ant-table-content {
        @include scrollbar('horizontal', 8px);
      }
    }

    &.ant-table-empty {
      .ant-table-content {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        overflow: hidden !important;
      }

      .ant-table-cell {
        padding: 0 !important;

        .ant-table-expanded-row-fixed {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .ant-table-tbody {
    > tr {
      &:nth-child(2n) {
        background: var(--new-bg-3, #1F1E21);
      }

      &.ant-table-row:hover > th,
      &.ant-table-row:hover > td,
      > th.ant-table-cell-row-hover,
      > td.ant-table-cell-row-hover {
        background-color: $color-bg-05;
      }

      > th,
      > td {
        padding: 16px 12px;
        border-bottom: none;

        &:first-child {
          padding-left: 16px;
        }

        &:last-child {
          padding-right: 16px;
        }
      }
      &.ant-table-placeholder:hover>th,
      &.ant-table-placeholder:hover>td,
      &.ant-table-placeholder {
        background: transparent;
      }
    }

  }
  //pagination
  .ant-table-pagination.ant-pagination {
    margin: 16px 0 0;
  }
}

[class^="ant-table"],
[class*=" ant-table"] {
  @include fs--headline-2-pc;

  .ant-typography {
    @include fs--headline-2-pc;
  }
}

//table input
.ant-table-cell {
  .ant-table-cell-editable {
    margin-top: -7px;
    margin-bottom: -7px;
    min-width: 140px;
  }
  .ant-input-affix-wrapper {
    border: 1px solid var(--new-border, #2B292E);
    background: transparent;

    .ant-btn-icon-only {
      color: $color-purple-3;
    }

    &:not(.ant-input-affix-wrapper-disabled) {
      &:hover {
        border: 1px solid var(--new-border, #2B292E);
      }
    }

    &.ant-input-affix-wrapper-lg {
      padding: 9px 9px 9px 15px;

      .ant-input-suffix {
        .ant-btn-icon-only {
          width: 36px;
          height: 36px;
          padding: 8px;

        }
      }
    }
  }
}

.ant-table-cell {
  height: 76px;
  &__flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
  }
  &__value {
    color: $color-white;
  }
  &__nowrap {
    white-space: nowrap;
  }
  &__date {
    min-width: 140px;
  }
  &__check {
    padding-left: 8px;
  }
  &__id {
    min-width: 360px;
    white-space: nowrap;
  }
  &__table-empty {
    padding: 96px 0;
    background: var(--new-bg-1, #131215);
  }
  &__table-empty-small {
    padding: 36px 0;
    background-color: transparent;
  }
}

@media screen and (max-width: 1024px) {
  .ant-table-wrapper {
    .ant-table {
      @include fs--headline-4-pc;
    }
  }

  [class^="ant-table"],
  [class*=" ant-table"] {
    @include fs--headline-4-pc;

    .ant-typography {
      @include fs--headline-4-pc;
    }
  }
}
